*{
    font-family: "Quicksand", sans-serif;
}
  

  .parent-container{
    font-family: "Chango", sans-serif;
    width: 100%;
    height: auto;
    padding-top: 20px;
     
  }
  /* Page Content Styles */
  .page-content-case-study-1 {
      width: 80%; /* 80% remains the same */
      margin: auto;
      padding-top: 10px;
      padding: 1.25rem; /* 20px converted to rem */
    }
    
    .page-content-case-study-1 h1 {
        padding-top: 100px;
      margin-top: 50px;
      padding-top: 10px;
    text-align: center;
    font-size: 2rem; /* 24px converted to rem */
  }
  
  .page-content-case-study-1 h2 {
 padding-top: 10px;   font-size: 1.25rem; /* 20px converted to rem */
  }
  
  .page-content-case-study-1 h3 {
 padding-top: 10px;   font-size: 1.125rem; /* 18px converted to rem */
  }
  
  .page-content-case-study-1 h4 {
 padding-top: 10px;   font-size: 1rem; /* 16px converted to rem */
  }
  
  .page-content-case-study-1 h5 {
 padding-top: 10px;   font-size: 0.875rem; /* 14px converted to rem */
  }
  
  .page-content-case-study-1 h6 {
 padding-top: 10px;   font-size: 0.75rem; /* 12px converted to rem */
  }
  
  .page-content-case-study-1 ul {
 padding-top: 10px;   list-style-type: disc;
    padding-left: 1.25rem; /* 20px converted to rem */
  }
  
  .page-content-case-study-1 img {
padding-top: 10px;    width: 100%; /* Width remains the same */
    margin-top: 1.25rem; /* 20px converted to rem */
    margin-bottom: 1.25rem; /* 20px converted to rem */
  }
  