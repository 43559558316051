* {
  padding: 0;
  margin: 0;
  background-color: #CFDCE5;
  font-family: "Quicksand", sans-serif;
}

.text-color {
  color: #004C48;

}

.color {
  background-color: #004C48;
}

.gray-color {
  background-color: #CFDCE5;
}





.Hero-section {
  height: 70vh;
  width: 100%;
}




.profile-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profile-img img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  cursor: pointer;
  transition: height 0.5s, width 0.5s;
}

.profile-img img:hover {
  height: 200px;
  width: 200px;
}